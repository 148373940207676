__webpack_public_path__ = cpAppSet.baseUrl + "theme/app/bundles/";

import '../../scss/style.scss';

import $ from "jquery";
import * as bootstrap from 'bootstrap'

import {__t} from "@commons/closerphp/i18n";

$.ajaxSetup({
    beforeSend: function (jqxhr, settings) {
        if (!settings.crossDomain) {
            jqxhr.setRequestHeader(cpAppSet.csrfToken.name, cpAppSet.csrfToken.value);
        }
    }
});

$(document).ajaxError(function (event, jqxhr, settings, exception) {
    if (exception !== "abort") {
        alert(__t("There was a problem communicating with the servers"));
    }
});
